import { useEffect, useState } from "react";
import { db } from "../context/Firebase"; // Ensure Firebase is initialized
import { collection, getDocs, writeBatch, doc } from "firebase/firestore";

const ImageList = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const subcategoriesSnapshot = await getDocs(
          collection(db, "subcategories")
        );
        if (subcategoriesSnapshot.empty) {
          console.log("No subcategories found.");
          return;
        }

        const subcategoryList = subcategoriesSnapshot.docs
          .map((doc) => {
            const data = doc.data();
            const imageUrl = data.image;

            if (!imageUrl) {
              console.warn(`No image URL for subcategory: ${doc.id}`);
              return null; // Skip this subcategory if there's no image URL
            }

            const imageName = decodeURIComponent(
              imageUrl.split("/").pop().split("?")[0]
            ); // Decode URL
            return { id: doc.id, name: imageName, url: imageUrl };
          })
          .filter((item) => item !== null); // Filter out null items

        setSubcategories(subcategoryList);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, []);

  // Function to update Firestore
  const handleUpdateImages = async () => {
    setUpdating(true);
    const batch = writeBatch(db); // Create batch update

    // Update subcategories
    subcategories.forEach((item) => {
      const newUrl = `https://ninjadeliveries-91007.web.app/SubCategories/${item.name}`; // Ensure correct folder structure
      const docRef = doc(db, "subcategories", item.id);
      batch.update(docRef, { image: newUrl });
    });

    try {
      await batch.commit(); // Commit all updates at once
      console.log("Firestore updated successfully!");
      alert("Subcategory images updated successfully!"); // Notify user
    } catch (error) {
      console.error("Error updating Firestore:", error);
      alert("Failed to update images.");
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div>
      <h2>Subcategories</h2>
      <ul>
        {subcategories.map((item, index) => (
          <li key={index}>
            <img src={item.url} alt={item.name} width={100} height={100} />
            <p>{item.name}</p>
          </li>
        ))}
      </ul>

      {/* Button to update Firestore */}
      <button onClick={handleUpdateImages} disabled={updating}>
        {updating ? "Updating..." : "Update Subcategory Images"}
      </button>
    </div>
  );
};

export default ImageList;
