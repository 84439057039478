import React, { useState, useEffect } from "react";
import { db, storage } from "../context/Firebase";
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import "../style/AddCategory.css";

const UpdateCategories = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [editCategory, setEditCategory] = useState(false);
  const [editSubcategory, setEditSubcategory] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [subImagePreview, setSubImagePreview] = useState({});

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, "categories"));
    const categoryList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCategories(categoryList);
  };

  const handleCategorySelect = async (category) => {
    setSelectedCategory(category);
    fetchSubcategories(category.id);
  };

  const fetchSubcategories = async (categoryId) => {
    const q = query(
      collection(db, "subcategories"),
      where("categoryId", "==", categoryId)
    );
    const querySnapshot = await getDocs(q);
    const subList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setSubcategories(subList);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubImageChange = (e, subId) => {
    const file = e.target.files[0];
    if (file) {
      setSubImagePreview((prev) => ({
        ...prev,
        [subId]: URL.createObjectURL(file),
      }));
    }
  };

  const handleEditCategory = async (name, imageFile) => {
    if (!selectedCategory) return;
    let imageUrl = selectedCategory.image;
    if (imageFile) {
      const imageRef = ref(storage, `categories/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      imageUrl = await getDownloadURL(imageRef);
    }
    await updateDoc(doc(db, "categories", selectedCategory.id), {
      name,
      image: imageUrl,
    });
    toast.success("Category updated!");
    fetchCategories();
    setEditCategory(false);
  };

  const handleEditSubcategory = async (subId) => {
    const name = document.getElementById(`subName-${subId}`).value;
    const imageFile = document.getElementById(`subImage-${subId}`).files[0];
    const categoryId = document.getElementById(`subCategory-${subId}`).value;
    let imageUrl = subcategories.find((sub) => sub.id === subId).image;

    if (imageFile) {
      const imageRef = ref(storage, `subcategories/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      imageUrl = await getDownloadURL(imageRef);
    }

    await updateDoc(doc(db, "subcategories", subId), {
      name,
      image: imageUrl,
      categoryId,
    });
    toast.success("Subcategory updated!");
    fetchSubcategories(selectedCategory.id);
    setEditSubcategory(null);
  };

  // Delete a category
  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      await deleteDoc(doc(db, "categories", categoryId));
      toast.success("Category deleted!");
      fetchCategories(); // Refresh the list
      setSelectedCategory(null); // Clear selected category
      setSubcategories([]); // Clear subcategories
    }
  };

  // Delete a subcategory
  const handleDeleteSubcategory = async (subId) => {
    if (window.confirm("Are you sure you want to delete this subcategory?")) {
      await deleteDoc(doc(db, "subcategories", subId));
      toast.success("Subcategory deleted!");
      fetchSubcategories(selectedCategory.id); // Refresh the list
    }
  };

  return (
    <div className="categories-container">
      <h2>Categories</h2>
      <select
        className="category-select"
        onChange={(e) =>
          handleCategorySelect(
            categories.find((cat) => cat.id === e.target.value)
          )
        }
      >
        <option value="">Select a category</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      {selectedCategory && (
        <div className="category-box">
          {!editCategory ? (
            <>
              <p className="category-name">{selectedCategory.name}</p>
              <img
                src={selectedCategory.image}
                alt={selectedCategory.name}
                className="category-image"
              />
              <button
                className="edit-button"
                onClick={() => setEditCategory(true)}
              >
                Edit
              </button>
              <button
                className="delete-button"
                onClick={() => handleDeleteCategory(selectedCategory.id)}
              >
                Delete
              </button>
            </>
          ) : (
            <>
              <input
                type="text"
                defaultValue={selectedCategory.name}
                id="categoryName"
                className="edit-input"
              />
              <input
                type="file"
                onChange={handleImageChange}
                id="categoryImage"
                className="file-input"
              />
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="preview-image"
                />
              )}
              <button
                className="save-button"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to save these changes?"
                    )
                  ) {
                    handleEditCategory(
                      document.getElementById("categoryName").value,
                      document.getElementById("categoryImage").files[0]
                    );
                  }
                }}
              >
                Save
              </button>
              <button
                className="cancel-button"
                onClick={() => setEditCategory(false)}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      )}
      <h3>Subcategories</h3>
      {subcategories.map((sub) => (
        <div key={sub.id} className="subcategory-box">
          {editSubcategory !== sub.id ? (
            <>
              <p className="subcategory-name">{sub.name}</p>
              <img
                src={sub.image}
                alt={sub.name}
                className="subcategory-image"
              />
              <button
                className="edit-button"
                onClick={() => setEditSubcategory(sub.id)}
              >
                Edit
              </button>
              <button
                className="delete-button"
                onClick={() => handleDeleteSubcategory(sub.id)}
              >
                Delete
              </button>
            </>
          ) : (
            <>
              <input
                type="text"
                defaultValue={sub.name}
                id={`subName-${sub.id}`}
                className="edit-input"
              />
              <input
                type="file"
                onChange={(e) => handleSubImageChange(e, sub.id)}
                id={`subImage-${sub.id}`}
                className="file-input"
              />
              {subImagePreview[sub.id] && (
                <img
                  src={subImagePreview[sub.id]}
                  alt="Preview"
                  className="preview-image"
                />
              )}
              <select
                id={`subCategory-${sub.id}`}
                defaultValue={sub.categoryId}
                className="category-select"
              >
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </select>
              <button
                className="save-button"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to save these changes?"
                    )
                  ) {
                    handleEditSubcategory(sub.id);
                  }
                }}
              >
                Save
              </button>
              <button
                className="cancel-button"
                onClick={() => setEditSubcategory(null)}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default UpdateCategories;
